import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import EditIcon from "../../../images/Edit-icon.svg";
import KeyTermsEditDialog from "./KeyTermsEditDialog";
import Modal from 'react-bootstrap/Modal';
import '../style.css';

export default function FormDialog (props) {

    const [dataSource, setDataSource] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecordsCount, setTotalRecordsCount] = useState(1);
    const [pageCount, setPageCount] = useState(5);

    const [currentPage, setPage] = useState(1);
    
    const [params, setParams] = useState(1);


    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [adjustedInspectionResult, setAdjustedInspectionResult] = useState('');
    const adjustedInspectionResultRef = useRef(null)
    const [reason, setReason] = useState('');
    const reasonRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const [commentList, setCommentList] = useState([]);

    let gridRef = useRef(null);
    const pagerRef = useRef(null);

    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            api.get(`form/${props.row.form_id}/comment`)
            .then(async (res) => {
                setDataSource(res.data.key_terms);
            },err=>{
                console.log(err.data)
                setDataSource(null);
            })
        }
        return () => mounted = false;
    },[]);

    const fetchUpdate = () => {
        api.get(`form/${props.row.form_id}/comment`)
        .then(async (res) => {
            setDataSource(res.data.key_terms);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    };

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const handleExit = () => {
      setIsConfirmed(false);
      props.setVisibility(false);
      setErrorMessage('');
      setAdjustedInspectionResult('');
      setReason('');
      adjustedInspectionResultRef.current.value = adjustedInspectionResult;
      reasonRef.current.value = reason;
    }

    const updateAdjustedInspectionResult = () => {
      const params = {
          form_id: props.row.form_id || props.row.formId,
          adjustedInspectionResult: adjustedInspectionResult,
          reasonsForAdjustment: reason,
      }
      console.log(params);
      api.post(`jobs/${props.jobID || props.row.jobId}/wronglyEndorsement`,params).then(async res => {
          console.log(res.data);
          props.fetchUpdate();
          handleExit();
      }, err => {
          setErrorMessage(err.response.data.message);
          console.log(err);
          setIsConfirmed(false);
      });
    }

    const keyTermTemplate = (row) => {
        return (
            <span className="keytermcolumn">
                {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
                <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        setRowInfo({
                            ...props.row,
                            ...row,
                        });
                        setShowEdit(true);
                    }} />
            </span>
        );

    }

    const closeEditDialog = () => {
        setShowEdit(false);
    };

    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const iptItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = (row) => {
        console.log(row);
        switch (row.inspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{row.inspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{row.inspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{row.inspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{row.inspectionResult}</span>
            default:
                return <span></span>
        }
    }

    const commentTemplate = (row) => {
        return(
            <p dangerouslySetInnerHTML={{ __html: row.comment }} ></p>
        );

    }

    const pagerTemplate = () => {
        return (
            <PagerComponent 
                totalRecordsCount={totalRecordsCount} 
                pageSize={pageSize} 
                pageCount={pageCount} 
                enableQueryString={true}
                enablePersistence={true}
                click={(e)=>{
                    setParams(window.location.href.slice(window.location.href.search('page')))
                    // setParams(e.currentPage)
                }}></PagerComponent>
        )
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            onEnter={()=>{
                setAdjustedInspectionResult(props.row.adjustedInspectionResult);
                setReason(props.row.reasonsForAdjustment);
                console.log(props.row);
            }}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='View RISC Form'
            // buttons={buttons}
            // footerTemplate={footerTemplate}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <div class="dialog-header">
                    View RISC Form
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                    }} />
                                    </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Predicted Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Reasons for Prediction</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{resultTemplate(props.row)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.reasonsForPrediction}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="float-left">
                    <span className="legend-item"><span className="red-dot"></span> Manual Adjusted</span>
                    <span className="legend-item"><span className="green-dot"></span> System Incorporated</span>
                </div>
                <hr class="dialog-hr" />
                <GridComponent
                    dataSource={dataSource}
                    height='100%'
                    allowTextWrap={true}
                    allowPaging={true}
                    allowSorting={true}
                    // pagerTemplate={pagerTemplate}
                    // created={()=>gridRef.current.setGridPager(pagerTemplate)}
                    pageSettings={{enableQueryString:true, pageSize:50, pageCount: 5, template:pagerTemplate}}
                    //created={fetchKeyTerms}
                    ref={g => gridRef = g}
                    >
                    <ColumnsDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="300px" field='commentField' headerText='Comment Field'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="" template={commentTemplate} field='comment' headerText='Comment'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page]}/>
                </GridComponent>
                <div style={{paddingTop: "20px"}}>
                        <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                        style={{float: "right"}}
                        onClick={()=>handleExit()}>Close</button>
                </div>
            </Modal>
            {
                showEdit && <KeyTermsEditDialog
                setVisibility={setShowEdit}
                row={rowInfo}
                jobID={props.jobId}
                fetchUpdate={fetchUpdate}
                />
            }
        </div>
        
    );

}