import React, {useState,useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent, Column } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";
import {useLocation, useParams } from "react-router-dom"
import LinkIcon from "../../../images/Link-icon.svg";
import EditIcon from "../../../images/Edit-icon.svg";
import moment, { min } from 'moment';
import "./style.css";
import DetailDialog from "../Dialog/DetailDialog";
import PredictedInspectionResutlEditDialog from "../../JobDetails/Dialog/PredictedInspectionResutlEditDialog";
import localStorageService from "../../../lib/LocalStorageService";

export default function IncorrectEndorsement (props) {
    const permission = localStorageService.getPermissions();
    const [dataSource, setDataSource] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecordsCount, setTotalRecordsCount] = useState(1);
    const [pageCount, setPageCount] = useState(5);

    const [currentPage, setPage] = useState(1);
    
    const location = useLocation();
    const [params, setParams] = useState(1);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);
    const pagerRef = useRef(null);

    const [uploadedByList, setUploadedByList] = useState([]);

    const initInspectionDateFrom = new Date();
    initInspectionDateFrom.setDate(initInspectionDateFrom.getDate() - 29);
    const fromYear = initInspectionDateFrom.getFullYear();
    const fromMonth = (initInspectionDateFrom.getMonth() + 1).toString().padStart(2, '0');
    const fromDay = initInspectionDateFrom.getDate().toString().padStart(2, '0');
    const initInspectionDateFromStr = `${fromYear}-${fromMonth}-${fromDay}`;

    const initInspectionDateTo = new Date();
    const toYear = initInspectionDateTo.getFullYear();
    const toMonth = (initInspectionDateTo.getMonth() + 1).toString().padStart(2, '0');
    const toDay = initInspectionDateTo.getDate().toString().padStart(2, '0');
    const initInspectionDateToStr = `${toYear}-${toMonth}-${toDay}`;

    const [inspectionDateFrom, setInspectionDateFrom] = useState(initInspectionDateFromStr);
    const [inspectionDateTo, setInspectionDateTo] = useState(initInspectionDateToStr);
    const [project, setProject] = useState('');
    const [jobId, setJobId] = useState('');
    const [uploadedBy, setUploadedBy] = useState(0);

    const projectRef = useRef(null);
    const jobIdRef = useRef(null);

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchIncorrectEndorsement();
        }

        return () => mounted = false;
    },[params])
    
    function fetchIncorrectEndorsement(){
        const _jobId = (jobId === '') ? 0 : jobId;
        api.get(`incorrectEndorsement?source=manual&inspectionDateFrom=${inspectionDateFrom}&inspectionDateTo=${inspectionDateTo}&project=${project}&jobId=${_jobId}&uploadedBy=${uploadedBy}`)
        .then(async (res) => {
            const report = res.data.incorrectEndorsementList;
            res.data.uploadedBy.unshift({
                "userId": 0,
                "displayName": "",
            });
            setDataSource(report);
            setUploadedByList(res.data.uploadedBy);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const iptItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = (row) => {
        switch (row.predictedInspectionResult) {
            case "Endorsed":
                return (
                    <span className="text-secondary" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDARE_JOB")!== -1&&(
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{ 
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                        )}
                    </span>
                );
            case "Cancelled":
                return (
                    <span className="text-primary" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDARE_JOB")!== -1&&(
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{ 
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                        )}
                    </span>
                );
            case "Failed":
                return (
                    <span className="text-danger" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDARE_JOB")!== -1&&(
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{ 
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                        )}
                    </span>
                );
            case "Undetermined":
                return (
                    <span className="text-info" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : null)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDARE_JOB")!== -1&&(
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{ 
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                        )}
                    </span>
                );
            default:
                return <span></span>
        }
    };

    const adjustedDateTemplate = (row) => {
        return (row.adjustedDate ? row.adjustedDate.substring(0, 16) : '');
    };

    const rowSelected = () => {
        const selectedrecords = dt.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0]
            // console.log(obj)
            setRowInfo(obj)
            setDetailClicked(true);
        }
    }

    const toggleItpReferenceVisibility = () => {
        if (dt.current) {
            if (itpReferenceVisibility) {
                dt.current.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                dt.current.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };

    return (
        <>
        <div className="content pb-0">
        <div className="heading mb-3">
            <h4 className="page-title">Incorrect Endorsement - Feedback List</h4>
        </div>
        <div className="top">
            <table>
                <tr>
                    <th>From Inspection Date</th>
                    <th>&nbsp;</th>
                    <th>To Inspection Date</th>
                    <th>Project</th>
                    <th>Job ID</th>
                    <th>Uploaded By</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                <tr>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="inspectionDateFrom" value={inspectionDateFrom} placeholder="From Inspection Date" floatLabelType="Never" width="200px" height="50px"
                        format="yyyy-MM-dd"
                        change={(e)=>{
                            if (e.value) {
                                setInspectionDateFrom(moment(e.value).format('YYYY-MM-DD'));
                            } else {
                                setInspectionDateFrom('');
                            }
                        }}
                        cleared={()=>{
                            setInspectionDateFrom('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        to
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="inspectionDateTo" value={inspectionDateTo} placeholder="To Inspection Date" floatLabelType="Never" width="200px" height="50px"
                        format="yyyy-MM-dd"
                        change={(e)=>{
                            if (e.value) {
                                setInspectionDateTo(moment(e.value).format('YYYY-MM-DD'));
                            } else {
                                setInspectionDateTo('');
                            }
                        }}
                        cleared={()=>{
                            setInspectionDateTo('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <TextBoxComponent ref={projectRef} value={project} input={e=>setProject(e.value)} change={e=>setProject(e.value)} width="200px" height="50px"
                        placeholder="Project" floatLabelType="Never" autocomplete={'off'}/>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <TextBoxComponent ref={jobIdRef} value={jobId} input={e=>setJobId(e.value)} change={e=>setJobId(e.value)} width="200px" height="50px"
                        placeholder="Job ID" floatLabelType="Never" autocomplete={'off'}/>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DropDownListComponent id="uploadedBy" placeholder="Uploaded By" floatLabelType="Never" width="200px" height="50px"
                        fields={{text:"displayName", value:"userId"}}
                        dataSource={uploadedByList}
                        change={(e)=>{
                            setUploadedBy(e.itemData.userId);
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            if (inspectionDateFrom && inspectionDateTo && inspectionDateFrom > inspectionDateTo) {
                                alert("'From Inspection Date' should be earlier than 'To Inspection Date'.");
                            } else {
                                fetchIncorrectEndorsement();
                            }
                        }}
                        >Filter</button>
                    </td>
                    <td style={{"width": "100%"}}>
                        <ButtonComponent className="btn btn-dark float-right" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent>
                    </td>
                </tr>
            </table>
            
            <div style={{"padding-top": "10px", "padding-right": "10px", "min-width": "400px"}}>
                <span className="legend-item"><span className="red-dot"></span> Manual Adjusted</span>
                <span style={{"min-width": "10px"}}>&nbsp;</span>
                <span className="legend-item"><span className="green-dot"></span> System Incorporated</span>
            </div>
        </div>
        </div>

        <div className="table-content p-3">
            <GridComponent
            dataSource={dataSource}
            height='60vh'
            rowSelected={rowSelected}
            allowTextWrap={true}
            allowPaging={true}
            allowSorting={true}
            ref={dt}
            // pagerTemplate={pagerTemplate}
            // created={()=>gridRef.current.setGridPager(pagerTemplate)}
            pageSettings={{pageSize: 50, pageCount: 5}}
            //created={fetchKeyTerms}
            >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='jobId' headerText='Job ID'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="130px" field='createdBy' headerText='Uploaded By'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='contract' headerText='Contract'></ColumnDirective>
                    {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={iptItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='location' headerText='Location'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionDate' headerText='Inspection Date' format='yMd'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={resultTemplate} field='predictedInspectionResult' headerText='Predicted Inspection Result'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='reasonsForPrediction' headerText='Reason For Prediction'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='adjustedInspectionResult' headerText='Adjusted Inspection Result'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='reasonsForAdjustment' headerText='Reasons For Adjustment' ></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="130px" field='adjustedBy' headerText='Adjusted By' ></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="150px" template={adjustedDateTemplate} field='adjustedDate' headerText='Adjusted Time' ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page]}/>
                
            </GridComponent>
        </div>
        {
            detailClicked && <DetailDialog 
            visibility={detailClicked}
            setVisibility={setDetailClicked}
            row={rowInfo}
            />
        }
        {
            showEdit && <PredictedInspectionResutlEditDialog
            setVisibility={setShowEdit}
            row={rowInfo}
            jobID={props.jobId}
            fetchUpdate={fetchIncorrectEndorsement}
            />
        }
        </>
    );
} 