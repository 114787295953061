import React, {useState,useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import api from "../../lib/API";
import {useLocation, useParams } from "react-router-dom"
import LinkIcon from "../../images/Link-icon.svg";
import EditIcon from "../../images/Edit-icon.svg";
import KeyTermsEditDialog from "./Dialog/KeyTermsEditDialog";
import localStorageService from "../../lib/LocalStorageService";

export default function KeyTerms (props) {
    const permission = localStorageService.getPermissions();
    const [dataSource, setDataSource] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecordsCount, setTotalRecordsCount] = useState(1);
    const [pageCount, setPageCount] = useState(5);

    const [currentPage, setPage] = useState(1);
    
    const location = useLocation();
    const [params, setParams] = useState(1);

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);
    let gridRef = useRef(null);
    const pagerRef = useRef(null);

    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            api.get(`jobs/${props.jobId}/keyTerm?${params}`)
            .then(async (res) => {
                const report = res.data.report;
                setPage(res.data.page);
                setDataSource(report);
                setTotalRecordsCount(res.data.totalRecord);
            },err=>{
                console.log(err.data)
                setDataSource(null);
            })
        }

        return () => mounted = false;
    },[params])

    const fetchUpdate = () => {
        api.get(`jobs/${props.jobId}/keyTerm?${params}`)
        .then(async (res) => {
            const report = res.data.report;
            setPage(res.data.page);
            setDataSource(report);
            setTotalRecordsCount(res.data.totalRecord);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    function fetchKeyTerms(){

        api.get(`jobs/${props.jobId}/keyTerm?page=${params.get("page")}}`)
        .then(async (res) => {
            console.log(params.get("page"));
            const report = res.data.report;
            setDataSource(report);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    const keyTermTemplate = (row) => {
        return (
            <span className="keytermcolumn">
                {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
                {permission?.search("UPDATE_JOB")!== -1&&(
                <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        setRowInfo(row);
                        setShowEdit(true);
                    }} />
                )}
            </span>
        );

    }

    const closeEditDialog = () => {
        setShowEdit(false);
    };

    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const iptItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = (row) => {
        switch (row.inspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{row.inspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{row.inspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{row.inspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{row.inspectionResult}</span>
            default:
                return <span></span>
        }
    }

    const commentTemplate = (row) => {
        return(
            <p dangerouslySetInnerHTML={{ __html: row.comment }} ></p>
        );

    }

    const pagerTemplate = () => {
        return (
            <PagerComponent 
                totalRecordsCount={totalRecordsCount} 
                pageSize={pageSize} 
                pageCount={pageCount} 
                enableQueryString={true}
                enablePersistence={true}
                click={(e)=>{
                    setParams(window.location.href.slice(window.location.href.search('page')))
                    // setParams(e.currentPage)
                }}></PagerComponent>
        )
    }

    const toggleItpReferenceVisibility = () => {
        if (gridRef) {
            if (itpReferenceVisibility) {
                gridRef.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                gridRef.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };
    
    return (
        <>
        <div className='control-pane'>
            <div className='control-section'>
            <div className="float-left">
                <span className="legend-item"><span className="red-dot"></span> Manual Adjusted</span>
                <span className="legend-item"><span className="green-dot"></span> System Incorporated</span>
            </div>
            <ButtonComponent className="btn btn-dark float-right" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent>
            <GridComponent
            dataSource={dataSource}
            height='100%'
            allowTextWrap={true}
            allowPaging={true}
            allowSorting={true}
            // pagerTemplate={pagerTemplate}
            // created={()=>gridRef.current.setGridPager(pagerTemplate)}
            pageSettings={{enableQueryString:true, pageSize:50, pageCount: 5, template:pagerTemplate}}
            //created={fetchKeyTerms}
            ref={g => gridRef = g}
            >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='contract' headerText='Contract'></ColumnDirective>
                    {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={iptItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='location' headerText='Location'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionDate' headerText='Inspection Date' format='yMd'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={resultTemplate} field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='commentField' headerText='Comment Field'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="160px" template={commentTemplate} field='comment' headerText='Comment'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page]}/>
                
            </GridComponent>
            </div>
        </div>
        {
            showEdit && <KeyTermsEditDialog
            setVisibility={setShowEdit}
            row={rowInfo}
            jobID={props.jobId}
            fetchUpdate={fetchUpdate}
            />
        }
        </>
    );
} 