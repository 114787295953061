import React, { useEffect, useState, useRef } from "react";
import { enableRipple } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { Button, Glyphicon } from 'react-bootstrap';
import "./style.css";
import { accessPredicate, ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';

import CreateLogo from "../../images/Create.svg";

import CreateUserDialog from "./Dialog/CreateUserDialog";

import ChangePasswordButton from "../../images/ChangePassword-highlighted.svg";
import DeleteUserButton from "../../images/DeleteUser-highlighted.svg";
import DeleteUserDisabled from "../../images/Delete-disabled.svg"
import EditUserButton from "../../images/EditUser-highlighted.svg";


import ChangePasswordDialog from "./Dialog/ChangePasswordDialog";
import EditDialog from "./Dialog/EditDialog";
import DeleteDialog from "./Dialog/DeleteDialog";
import ErrorDialog from "./Dialog/ErrorDialog";

import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

export default function UserManagement(props){
    const [createVisibility,setCreate] = useState(false);
    const [changePasswordVisibility,setChange] = useState(false);
    const [deleteVisibility,setDelete] = useState(false);
    const [EditVisibility,setEdit] = useState(false);
    const [deleteError, setDeleteError] = useState('');
    const [errorVisibility, setErrorVisibility] = useState(false);

    const [rowInfo, setRow] = useState({});

    const [dataSource, setDataSource] = useState(null);


    const [username, setUsername] = useState('');
    const [rolesList, setRolesList] = useState();
    const roleRef = useRef(null);
    var gridRef;
    function fetchUsers(){

            api.get('/roles').then(async (res) => {
                setRolesList(res.data);

            }, err => {
                console.log(err.data);
            })
            api.get('/users').then(async (res) => {
                setDataSource(res.data);
                },err=>{
                    console.log(err.data)
            })
    }

    useEffect(()=>{
        let mounted = true;
        api.get('/roles').then(async (res) => {
            if(mounted){
                setRolesList(res.data);
            }
        }, err => {
            console.log(err.data);
        })
        api.get('/users').then(async (res) => {
            if(mounted){
                setDataSource(res.data);
            }
            },err=>{
                console.log(err.data)
        })

        return () => {
            mounted = false;
        }
    },[])
    // useEffect(()=>{
    //     console.log(roleRef.current.contentModule.rows);
    //     roleRef.current.contentModule.rows.forEach((e,index)=>{
    //         const roleName = rolesList[rolesList.findIndex(e=>{return e.roleId === props.row.roleRef.current.contentModule.rows[index].data.roleId})].roleName
    //         roleRef.current.contentModule.rows[index].data.roleId = roleName;
    //     })
    // },[roleRef])

    const fetchRoles = () =>{
        api.get('/roles').then(async (res) => {
            setRolesList(res.data);

        }, err => {
            console.log(err.data);
        })
    }


    const roleTemplate = (row) => {
        return <span>{row.roleId}</span>
    }

    const ButtonsTemplate = (row) => {
        return (
        <>
            {
                process.env.REACT_APP_PASSWORD_LOGIN ? <img src={ChangePasswordButton} style={{cursor:"pointer"}} alt="" 
                    onClick={()=>{
                        setChange(true);
                        setRow(row);}}
                /> : null
            }
            <img src={EditUserButton} style={{cursor:"pointer"}} alt=""
                onClick={()=>{
                    setEdit(true);
                    setRow(row);
                }} />
            {
                localStorageService.getUsername() && (row.username.toLowerCase() !== localStorageService.getUsername().toLowerCase() ? <img src={DeleteUserButton} style={{cursor:"pointer"}} alt="" 
                    onClick={()=>{
                        setDelete(true);
                        setRow(row);
                }} /> : <img src={DeleteUserDisabled} alt="" />)
            }
        </>
        ); 
    };
    
    const usernameTemplate = (args) => {
        return (
            <span>{args.username}</span>
        );
    }

    return (
        <>
        <div className="content">
            
            <div className="row align-items-start">
                <div className="col">
                    <h4 className="page-title">User Management</h4>
                </div>
            </div>
            <button type="button" className="btn btn-dark mt-3" onClick={()=>setCreate(true)}>
                <img src={CreateLogo} alt="" />{` `} Create
            </button>
        
            {/* <button type="button" className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                </svg> Delete
            </button>                 */}
        </div>
            <div className="table-content mt-0 pr-3 pl-3">
                <GridComponent  ref={roleRef} dataSource={dataSource} allowPaging={true} allowSorting={true} pageSettings={{pageSize:50}} height='60vh' >
                <ColumnsDirective>
                    <ColumnDirective field="username" headerText='Username' width='25%' template={usernameTemplate} />
                    <ColumnDirective field="roleName" headerText='Role' width='10%'  />
                    <ColumnDirective field="displayName" headerText='Name' width='20%'  />
                    <ColumnDirective field="section" headerText='Section' width='10%' />
                    <ColumnDirective headerText="" template={ButtonsTemplate} width="35%" textAlign="right" />
                </ColumnsDirective>
                <Inject services={[Page]}/>
                </GridComponent>
            </div>

            <CreateUserDialog visibility={createVisibility} setVisibility={setCreate} fetchUsers={fetchUsers} rolesList={rolesList} />
            <ChangePasswordDialog visibility={changePasswordVisibility} setVisibility={setChange} row={rowInfo} dataSource={dataSource} />
            <EditDialog visibility={EditVisibility} setVisibility={setEdit} row={rowInfo} fetchUsers={fetchUsers} rolesList={rolesList} />
            <DeleteDialog visibility={deleteVisibility} setVisibility={setDelete} row={rowInfo} fetchUsers={fetchUsers} dataSource={dataSource} setDeleteError={setDeleteError} setErrorVisibility={setErrorVisibility} />
            <ErrorDialog visibility={errorVisibility} setVisibility={setErrorVisibility} deleteError={deleteError} setDeleteError={setDeleteError} fetchUsers={fetchUsers} />
        </>
    );
}