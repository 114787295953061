import React, {useState,useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";
import {useLocation, useParams } from "react-router-dom"
import LinkIcon from "../../../images/Link-icon.svg";
import EditIcon from "../../../images/Edit-icon.svg";
import KeyTermsEditDialog from "../../JobDetails/Dialog/KeyTermsEditDialog";
import moment, { min } from 'moment';
import "./style.css";
import localStorageService from "../../../lib/LocalStorageService";

export default function KeyTerms (props) {
    const permission = localStorageService.getPermissions();
    const [dataSource, setDataSource] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecordsCount, setTotalRecordsCount] = useState(1);
    const [pageCount, setPageCount] = useState(5);

    const [currentPage, setPage] = useState(1);
    
    const location = useLocation();
    const [params, setParams] = useState(1);

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);
    let gridRef = useRef(null);
    const pagerRef = useRef(null);

    const [uploadedByList, setUploadedByList] = useState([]);
    const [disciplineList, setDisciplineList] = useState([]);
    const [keyTermList, setKeyTermList] = useState([]);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    const initInspectionDateFrom = new Date();
    initInspectionDateFrom.setDate(initInspectionDateFrom.getDate() - 29);
    const fromYear = initInspectionDateFrom.getFullYear();
    const fromMonth = (initInspectionDateFrom.getMonth() + 1).toString().padStart(2, '0');
    const fromDay = initInspectionDateFrom.getDate().toString().padStart(2, '0');
    const initInspectionDateFromStr = `${fromYear}-${fromMonth}-${fromDay}`;

    const initInspectionDateTo = new Date();
    const toYear = initInspectionDateTo.getFullYear();
    const toMonth = (initInspectionDateTo.getMonth() + 1).toString().padStart(2, '0');
    const toDay = initInspectionDateTo.getDate().toString().padStart(2, '0');
    const initInspectionDateToStr = `${toYear}-${toMonth}-${toDay}`;

    const [inspectionDateFrom, setInspectionDateFrom] = useState(initInspectionDateFromStr);
    const [inspectionDateTo, setInspectionDateTo] = useState(initInspectionDateToStr);
    const [project, setProject] = useState('');
    const [jobId, setJobId] = useState('');
    const [uploadedBy, setUploadedBy] = useState(0);
    const [discipline, setDiscipline] = useState("");
    const [keyTerm, setKeyTerm] = useState("");

    const projectRef = useRef(null);
    const jobIdRef = useRef(null);
   
    const fullKeyTermList = {
        "General": [
            "Non compliance with specifications / drawings / design / standard",
            "Incorrect material type / size / pattern / colour",
            "Inspection item not found",
            "Instrument damaged",
            "No permit to work",
            "Deformation / deflection",
            "Poor workmanship",
            "wrong / missing information",
            "duplicate risc form",
            "inappropriate information",
            "re-sequence work",
            "work not ready",
            "contractor cancel inspection",
            "Unpredictable events",
            "Late Submission",
            "outstanding works",
            "outstanding submission",
        ],
        "ABWF": [
            "Blockwork & Partitions Defects",
            "Doors & Shutters Defects",
            "Facade & Canopies defects",
            "False ceiling & lightings defects",
            "Fire protectoin defects",
            "Handrails & Balustrades Defects",
            "Signage Defects",
            "Tiling / VE / Aluminum Panels defects",
            "Other ABWF Issues",
        ],
        "CS": [
            "Concrete defects",
            "Incorrect concrete grade",
            "Drainage works defects",
            "Roadwork defects",
            "Steel related defects",
            "defective coupler",
            "Civil & Structure - Water Proofing Issue",
            "Bridge & Viaduct related defects",
            "Pre-cast elemet defects",
        ],
        "FDN": [
            "Foundation defects",
        ],
        "GEO": [
            "Geotechnical Defects",
            "Observable Geotechnical Risks",
        ],
        "OHL": [
            "Overhead Line - Support Issue",
            "Overhead Line - Wire Issues",
            "Overhead Line - Switch Gear / Equipment Issue",
        ],
        "PWY": [
            "Permanent Way / Trackworks Defects",
        ],
        "EM": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ],
        "BS": [
            "Inaccessibility",
            "lack of protection",
            "Support defects",
            "improper labelling",
            "E&M - Energy Related Defects",
            "Electrical Installation Related Defects",
            "Lightning System related defects",
            "Pipework & Equipment defects",
            "Water quality issue",
            "Control System defects",
            "ECS & TECS related defects",
            "Mechanical Installation defects",
            "SIG System related Defects",
            "E&M - RS related defects",
            "Communication System Defects",
            "Lifts & Escalator Defects",
            "Doors related Defects",
        ],
        "SUR": [
        ],
    }

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchKeyTerms();
            initDisciplineList();
            initKeyTermList();
        }

        return () => mounted = false;
    },[])

    function initDisciplineList() {
        const disciplineList = ["", "General", ...Object.keys(fullKeyTermList).filter(key => key !== "General").sort()];
        setDisciplineList(disciplineList);
    }

    function initKeyTermList() {
        setKeyTermList(["", ...Object.values(fullKeyTermList).flat().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())), "Other"]);
    }

    function updateDiscipline(discipline) {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        console.log(disciplineKeyTermList);
        if (discipline){
            disciplineKeyTermList = ["", ...disciplineKeyTermList, "Other"];
        }
        else{
            disciplineKeyTermList = ["", ...Object.values(fullKeyTermList).flat(), "Other"];
        }
        
        console.log(disciplineKeyTermList);
        setKeyTermList(disciplineKeyTermList.sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase())));
        setDiscipline(discipline);
        setKeyTerm(disciplineKeyTermList[0]);
    }

    function updateKeyTerm(keyTerm) {
        setKeyTerm(keyTerm);
    }

    function fetchKeyTerms(){
        const _jobId = (jobId === '') ? 0 : jobId;
        api.get(`keyTerms?source=manual&inspectionDateFrom=${inspectionDateFrom}&inspectionDateTo=${inspectionDateTo}&project=${project}&jobId=${_jobId}&uploadedBy=${uploadedBy}&discipline=${discipline}&keyTerm=${keyTerm}`)
        .then(async (res) => {
            const report = res.data.keyTermList;
            res.data.uploadedBy.unshift({
                "userId": 0,
                "displayName": "",
            });
            setDataSource(report);
            setUploadedByList(res.data.uploadedBy);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    const keyTermTemplate = (row) => {
        let className;
        if (row.status === "Manual Added") {
            className = "added-key";
        } else if (row.status === "AI Generated") {
            className = "ai-key";
        } else if (row.status === "Deleted AI Generated") {
            className = "del-ai-key";
        } else {
            className = "Delete AI"; 
        }
    
        return (
            <span className="keytermcolumn">
                {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                <span className={`badge ${className} p-2 font-weight-normal`} id="keytermfield">{row.keyTerm}</span>
                {permission?.search("UPDATE_JOB") !== -1 && (
                <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        setRowInfo(row);
                        setShowEdit(true);
                    }} />
                )}
            </span>
        );
    };

    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const iptItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = (row) => {
        switch (row.inspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{row.inspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{row.inspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{row.inspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{row.inspectionResult}</span>
            default:
                return <span></span>
        }
    }

    const commentTemplate = (row) => {
        return(
            <p dangerouslySetInnerHTML={{ __html: row.comment }} ></p>
        );

    }

    const adjustedDateTemplate = (row) => {
        return (row.adjustedDate ? row.adjustedDate.substring(0, 16) : '');
    };

    const toggleItpReferenceVisibility = () => {
        if (gridRef) {
            if (itpReferenceVisibility) {
                gridRef.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                gridRef.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };

    return (
        <>
        <div className="content pb-0">
        <div className="heading mb-3">
            <h4 className="page-title">Key Terms - Feedback List</h4>
        </div>
        <div className="top">
            <table>
                <tr>
                    <th>From Inspection Date</th>
                    <th>&nbsp;</th>
                    <th>To Inspection Date</th>
                    <th>Project</th>
                    <th>Job ID</th>
                    <th>Uploaded By</th>
                    <th>Discipline</th>
                    <th>Key Term</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                <tr>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="inspectionDateFrom" value={inspectionDateFrom} placeholder="From Inspection Date" floatLabelType="Never" width="200px" height="50px"
                        format="yyyy-MM-dd"
                        change={(e)=>{
                            if (e.value) {
                                setInspectionDateFrom(moment(e.value).format('YYYY-MM-DD'));
                            } else {
                                setInspectionDateFrom('');
                            }
                        }}
                        cleared={()=>{
                            setInspectionDateFrom('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        to
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="inspectionDateTo" value={inspectionDateTo} placeholder="To Inspection Date" floatLabelType="Never" width="200px" height="50px"
                        format="yyyy-MM-dd"
                        change={(e)=>{
                            if (e.value) {
                                setInspectionDateTo(moment(e.value).format('YYYY-MM-DD'));
                            } else {
                                setInspectionDateTo('');
                            }
                        }}
                        cleared={()=>{
                            setInspectionDateTo('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <TextBoxComponent ref={projectRef} value={project} input={e=>setProject(e.value)} change={e=>setProject(e.value)} width="200px" height="50px"
                        placeholder="Project" floatLabelType="Never" autocomplete={'off'}/>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <TextBoxComponent ref={jobIdRef} value={jobId} input={e=>setJobId(e.value)} change={e=>setJobId(e.value)} width="200px" height="50px"
                        placeholder="Job ID" floatLabelType="Never" autocomplete={'off'}/>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DropDownListComponent id="uploadedBy" placeholder="Uploaded By" floatLabelType="Never" width="200px" height="50px"
                        fields={{text:"displayName", value:"userId"}}
                        dataSource={uploadedByList}
                        change={(e)=>{
                            setUploadedBy(e.itemData.userId);
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DropDownListComponent id="discipline" placeholder="Discipline" floatLabelType="Never" width="200px" height="50px"
                        dataSource={disciplineList}
                        change={(e)=>{
                            updateDiscipline(e.itemData?.value);
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DropDownListComponent id="keyTerm" placeholder="Key Term" floatLabelType="Never" width="200px" height="50px"
                        dataSource={keyTermList}
                        change={(e)=>{
                            updateKeyTerm(e.itemData?.value);
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            if (inspectionDateFrom && inspectionDateTo && inspectionDateFrom > inspectionDateTo) {
                                alert("'From Inspection Date' should be earlier than 'To Inspection Date'.");
                            } else {
                                fetchKeyTerms();
                            }
                        }}
                        >Filter</button>
                    </td>
                    <td style={{"width": "100%"}}>
                        <ButtonComponent className="btn btn-dark float-right" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent>
                    </td>
                </tr>
            </table>
            
         

            <div style={{"padding-top": "10px", "padding-right": "10px", "min-width": "400px"}}>
                <span className="legend-item"><span className="red-dot"></span> Manual Adjusted</span>
                <span style={{"min-width": "10px"}}>&nbsp;</span>
                <span className="legend-item"><span className="green-dot"></span> System Incorporated</span>
                <span style={{"min-width": "20px"}}>&nbsp;</span>
                <span className="added-key">Manual Added Key Term</span>
                <span style={{"min-width": "20px"}}>&nbsp;</span>
                <span className="ai-key">AI Generated Key Term</span>
                <span style={{"min-width": "20px"}}>&nbsp;</span>
                <span className="del-ai-key"> Deleted AI Generated Key Term</span>

            </div>
        </div>
        </div>

        <div className="table-content p-3">
            <GridComponent
            dataSource={dataSource}
            height='60vh'
            width='100%'
            allowTextWrap={true}
            allowPaging={true}
            allowSorting={true}
            // pagerTemplate={pagerTemplate}
            // created={()=>gridRef.current.setGridPager(pagerTemplate)}
            pageSettings={{pageSize: 50, pageCount: 5}}
            ref={g => gridRef = g}
            //created={fetchKeyTerms}
            >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="180px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='jobId' headerText='Job ID'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="130px" field='createdBy' headerText='Uploaded By'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='contract' headerText='Contract'></ColumnDirective>
                    {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={iptItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='location' headerText='Location'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionDate' headerText='Inspection Date' format='yMd'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={resultTemplate} field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='commentField' headerText='Comment Field'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="300px" template={commentTemplate} field='comment' headerText='Comment'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="300px" field='reasons_for_adjustment' headerText='Reasons For Adjustment' ></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="130px" field='adjustedBy' headerText='Adjusted By' ></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="150px" template={adjustedDateTemplate} field='adjustedDate' headerText='Adjusted Time' ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page]}/>
                
            </GridComponent>
        </div>
        {
            showEdit && <KeyTermsEditDialog
            setVisibility={setShowEdit}
            row={rowInfo}
            jobID={props.jobId}
            fetchUpdate={fetchKeyTerms}
            />
        }
        </>
    );
} 