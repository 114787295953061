import React, {useState, useRef, useEffect} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import Modal from 'react-bootstrap/Modal';

export default function PredictedInspectionResutlEditDialog (props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [adjustedInspectionResult, setAdjustedInspectionResult] = useState('');
    const adjustedInspectionResultRef = useRef(null)
    const [reason, setReason] = useState('');
    const reasonRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    
    useEffect(()=>{
        let mounted = true;
        if(props.visibility && mounted){
        }
        return () => mounted = false;
    },[]);

    const getItpReference = (itpReference) => {
        const parts = itpReference.split('#####');
        return parts[0];
    };

    const getItpItemNo = (itpReference) => {
        const parts = itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = () => {
        switch (props.row.predictedInspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{props.row.predictedInspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{props.row.predictedInspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{props.row.predictedInspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{props.row.predictedInspectionResult}</span>
            default:
                return <span></span>
        }
    };

    const keyTermTemplate = (row) => {
        return (
            <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{row.keyTerm}</span>
        );
    }

    const handleExit = () => {
      setIsConfirmed(false);
      props.setVisibility(false);
      setErrorMessage('');
      setAdjustedInspectionResult('');
      setReason('');
      adjustedInspectionResultRef.current.value = adjustedInspectionResult;
      reasonRef.current.value = reason;
    }

    const updateAdjustedInspectionResult = () => {
      const params = {
          form_id: props.row.form_id || props.row.formId,
          adjustedInspectionResult: adjustedInspectionResult,
          reasonsForAdjustment: reason,
      }
      console.log(params);
      api.post(`jobs/${props.jobID || props.row.jobId}/wronglyEndorsement`,params).then(async res => {
          console.log(res.data);
          props.fetchUpdate();
          handleExit();
      }, err => {
          setErrorMessage(err.response.data.message);
          console.log(err);
          setIsConfirmed(false);
      });
    }

    const resetAdjustedInspectionResult = () => {
        const params = {
            form_id: props.row.form_id || props.row.formId,
        }
        console.log(params);
        api.put(`jobs/${props.jobID || props.row.jobId}/wronglyEndorsement`,params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
        });
      }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            onEnter={()=>{
                setAdjustedInspectionResult(props.row.adjustedInspectionResult);
                setReason(props.row.reasonsForAdjustment);
                console.log(props.row);
            }}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            header='Key Terms'
            // buttons={buttons}
            // footerTemplate={footerTemplate}
            isModal={true}
            position={{X:'center', Y:'center'}}
            // ref={dialog => dialogInstance = dialog} 
            overlayClick = {() => props.setVisibility(false)}>
                <div class="dialog-header">
                    Edit Predicted Inspection Result
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Link to iSuper</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Project</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Contract</p></td>
                                {/*<td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>CFD</p></td>*/}
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>RISC No</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>ITP Reference</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>ITP Item No</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Discipline</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Location</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">
                                    <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        window.open(props.row.formLink, "_blank", "noopener,noreferrer");
                                    }} />
                                    </p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.project}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.contract}</p></td>
                                {/*<td><p className="mb-4 ml-1 mr-2">{props.row.cfd}</p></td>*/}
                                <td><p className="mb-4 ml-1 mr-2">{props.row.riscNo}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpReference(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{getItpItemNo(props.row.itpReference)}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.discipline}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.location}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Date</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" width="10%" style={inlineStyle}>Predicted Inspection Result</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Reasons for Prediction</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionDate}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.inspectionResult}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{resultTemplate()}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.reasonsForPrediction}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr class="dialog-hr" />
                <div>
                    <p className="font-weight-bold text-dark" width="10%" style={{...inlineStyle, paddingTop: "10px", paddingBottom: "10px"}}>Update Predicted Inspection Result</p>
                    <span style={{paddingLeft: "20px"}}>
                        <RadioButtonComponent label="Endorsed" checked={adjustedInspectionResult == 'Endorsed'} change={(e)=>setAdjustedInspectionResult('Endorsed')} name="adjustedInspectionResult" disabled={props.row.isIncorporated || props.row.predictedInspectionResult == "Endorsed"}/>
                    </span>
                    <span style={{paddingLeft: "20px"}}>
                        <RadioButtonComponent label="Failed" checked={adjustedInspectionResult == 'Failed'} change={(e)=>setAdjustedInspectionResult('Failed')} name="adjustedInspectionResult" disabled={props.row.isIncorporated || props.row.predictedInspectionResult == "Failed"}/>
                    </span>
                    <span style={{paddingLeft: "20px"}}>
                      <RadioButtonComponent label="Cancelled" checked={adjustedInspectionResult == 'Cancelled'} change={(e)=>setAdjustedInspectionResult('Cancelled')} name="adjustedInspectionResult" disabled={props.row.isIncorporated || props.row.predictedInspectionResult == "Cancelled"}/>
                    </span>
                    <p className="font-weight-bold text-dark" width="10%" style={{...inlineStyle, paddingTop: "10px", paddingBottom: "10px"}}>Reason</p>
                    <TextBoxComponent
                        ref={reasonRef}
                        value={reason}
                        placeholder="Reason" 
                        floatLabelType="Never"
                        disabled={props.row.isIncorporated}
                        input={(e)=>setReason(e.value)}
                        change={(e)=>setReason(e.value)} />
                    <div className="col-7">
                        {errorMessage && <p style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
                    </div>
                </div>
                <div>
                    {
                        props.row.isIncorporated
                        ?
                        <div style={{paddingTop: "20px"}}>
                            <span style={{float: "right"}}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                                onClick={()=>handleExit()}>Close</button>
                            </span>
                        </div>
                        :
                        <div style={{paddingTop: "20px"}}>
                            <button
                                className="btn bg-danger pl-4 pr-4 mr-2 mb-2" style={{color: "white"}}
                                onClick={()=>{
                                    resetAdjustedInspectionResult();
                                }}
                                >Reset</button>
                            <span style={{float: "right"}}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                                onClick={()=>handleExit()}>Cancel</button>
                                <button
                                ref={confirmRef}
                                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                disabled={!adjustedInspectionResult || !reason}
                                onClick={()=>{
                                    setIsConfirmed(true);
                                    updateAdjustedInspectionResult();
                                }}
                                >Confirm</button>
                            </span>
                        </div>
                    }
                </div> 
            </Modal>
        </div>
        
    );

}