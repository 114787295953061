import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import api from "../../lib/API";
import { useLocation, useParams, useHistory } from "react-router-dom"
import LinkIcon from "../../images/Link-icon.svg";
import EditIcon from "../../images/Edit-icon.svg";

import DetailDialog from "./Dialog/DetailDialog";
import PredictedInspectionResutlEditDialog from "./Dialog/PredictedInspectionResutlEditDialog";
import LocalStorageService from "../../lib/LocalStorageService";
export default function IncorrectEndorsement(props) {
    const permission = LocalStorageService.getPermissions();
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`jobs/${props.jobId}/wronglyEndorsement?${params}`)
                .then(async (res) => {
                    const report = res.data.report;
                    setDataSource(report);
                    setTotalRecord(res.data.totalRecord);
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                })
        }

        return () => mounted = false;
    }, [params])

    const fetchUpdate = () => {
        api.get(`jobs/${props.jobId}/wronglyEndorsement?${params}`)
            .then(async (res) => {
                const report = res.data.report;
                setDataSource(report);
                setTotalRecord(res.data.totalRecord);
            }, err => {
                console.log(err.data)
                setDataSource(null);
            })
    }

    const formLinkTemplate = (row) => {
        return (
            <img style={{ cursor: "pointer" }} src={LinkIcon} alt=""
                onClick={(e) => {
                    e.stopPropagation();
                    window.open(row.formLink, "_blank", "noopener,noreferrer");
                }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const iptItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : '');
    };

    const resultTemplate = (row) => {
        switch (row.predictedInspectionResult) {
            case "Endorsed":
                return (
                    <span className="text-secondary" style={{ whiteSpace: "nowrap" }}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDATE_JOB") !== -1 && (
                            <img className="keytermedit" style={{ cursor: "pointer" }} src={EditIcon} alt=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRowInfo(row);
                                    setShowEdit(true);
                                }} />
                        )}
                    </span>
                );
            case "Cancelled":
                return (
                    <span className="text-primary" style={{ whiteSpace: "nowrap" }}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDATE_JOB") !== -1 && (
                            <img className="keytermedit" style={{ cursor: "pointer" }} src={EditIcon} alt=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRowInfo(row);
                                    setShowEdit(true);
                                }} />
                        )}
                    </span>
                );
            case "Failed":
                return (
                    <span className="text-danger" style={{ whiteSpace: "nowrap" }}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDATE_JOB") !== -1 && (
                            <img className="keytermedit" style={{ cursor: "pointer" }} src={EditIcon} alt=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRowInfo(row);
                                    setShowEdit(true);
                                }} />
                        )}
                    </span>
                );
            case "Undetermined":
                return (
                    <span className="text-info" style={{ whiteSpace: "nowrap" }}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : null)}
                        {row.predictedInspectionResult}
                        {permission?.search("UPDATE_JOB") !== -1 && (
                            <img className="keytermedit" style={{ cursor: "pointer" }} src={EditIcon} alt=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRowInfo(row);
                                    setShowEdit(true);
                                }} />
                        )}
                    </span>
                );
            default:
                return <span></span>
        }
    };

    const closeEditDialog = () => {
        setShowEdit(false);
    };

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={totalRecord}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const rowSelected = () => {
        const selectedrecords = dt.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0]
            // console.log(obj)
            setRowInfo(obj)
            setDetailClicked(true);
        }
    }

    const toggleItpReferenceVisibility = () => {
        if (dt.current) {
            if (itpReferenceVisibility) {
                dt.current.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                dt.current.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };

    return (
        <>
            <div className='control-pane'>
                <div className='control-section' >
                    <div className="float-left">
                        <span className="legend-item"><span className="red-dot"></span> Manual Adjusted</span>
                        <span className="legend-item"><span className="green-dot"></span> System Incorporated</span>
                    </div>
                    <ButtonComponent className="btn btn-dark float-right" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent>
                    <GridComponent
                        height='100%'
                        rowSelected={rowSelected}
                        allowPaging={true}
                        allowTextWrap={true}
                        allowSorting={true}
                        ref={dt}
                        //queryString={true}
                        dataSource={dataSource}
                        pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5, template: pagerTemplate }} >
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='contract' headerText='Contract'></ColumnDirective>
                            {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={iptItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='location' headerText='Location'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionDate' headerText='Inspection Date' format='yMd'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="160px" template={resultTemplate} field='predictedInspectionResult' headerText='Predicted Inspection Result'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="" field='reasonsForPrediction' headerText='Reason For Prediction'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />

                    </GridComponent>
                </div>
            </div>
            {
                detailClicked && <DetailDialog
                    visibility={detailClicked}
                    setVisibility={setDetailClicked}
                    row={rowInfo}
                    jobID={props.jobId}
                />
            }
            {
                showEdit && <PredictedInspectionResutlEditDialog
                    setVisibility={setShowEdit}
                    row={rowInfo}
                    jobID={props.jobId}
                    fetchUpdate={fetchUpdate}
                />
            }
        </>
    );
}